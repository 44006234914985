import React, { useState, useRef } from 'react';
import { Button, Dialog, Stack, DialogTitle, DialogContent, IconButton, Typography, Grid, Box, Menu, MenuItem, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from 'react-device-detect';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import StorefrontIcon from '@mui/icons-material/Storefront';

// import CredentialsDlg from '../dialog/Credentials'
import StyledButton from './StyledButton';

import useSingin from '../../hooks/useSignin';
import Page from '../Page';
import { getPaypalUserEmail, postRequest } from '../../utils/common';
import { MFab } from '../@material-extend';
import RingAvatar from '../RingAvatar';
import PaperRecord from '../PaperRecord';
import { PaypalLoginButton } from '../paypal/PaypalLoginButton';
import { TextFieldStyle } from '../CustomInput';

const DialogStyle = styled(Page)(() => ({
  "& .text-field": {
    width: "100%"
  },
  "& .password-text-field": {
    marginBottom: "15px"
  },
  "& .footer-text": {
    marginTop: "15px"
  },
  "& .forgot-password": {
    width: "100%",
    textAlign: "center",
    fontSize: "14px"
  }
}));

export default function CentralizedSignInDialog() {
  const { sessionId, setSessionId, getUser, user } = useSingin();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const forgotPasswordEmailRef = useRef();
  const loginEmailRef = useRef();
  const loginPasswordRef = useRef();
  const registerPasswordRef = useRef();
  const registerEmailRef = useRef();
  const registerNameRef = useRef();
  const registerSurnamedRef = useRef();
  const amountRef = useRef();
  const paypalEmailRef = useRef();
  const [isOpenAccountPopup, setOpenAccountPopup] = useState(null);
  const [openSignIn, setOpenSignInDlg] = useState(false);
  const [openSignUp, setOpenSignUpDlg] = useState(false);
  const [openForgotPassword, setOpenForgotPasswordDlg] = useState(false);
  const [isOpenRedeemPointDlg, setIsOpenRedeemPointDlg] = useState(false);
  const [isOpenAreYouSureDlg, setIsOpenAreYouSureDlg] = useState(false);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [emailFromURL, setEmailFromURL] = useState("");
  const [avatarUrl, setAvatarUrl] = React.useState('/static/user-home.svg');

  const { setOpenCredentials } = useSingin();
  const [searchParams, setSearchParams] = useSearchParams();
  React.useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    const signUp = searchParams.get("signup");
    if (signUp) {
      const emailFromQuery = searchParams.get("email");
      setEmailFromURL(decodeURIComponent(emailFromQuery));
      setOpenSignUpDlg(true);
    }

    const signIn = searchParams.get("signin") === "true";
    if (signIn) {
      const emailFromQuery = searchParams.get("email");
      if(emailFromQuery){
        setEmailFromURL(decodeURIComponent(emailFromQuery));
      }
      setOpenSignInDlg(true);
    }

    getUser();
    if (searchParams.get("id_token")) {
      const email = await getPaypalUserEmail(searchParams.get("id_token"))
      setEmail(email);
      setIsOpenRedeemPointDlg(true);
    }
  };

  const signInRequest = () => {
    const email = loginEmailRef.current.value;
    const password = loginPasswordRef.current.value;

    if (!validateInput(email, 'email', enqueueSnackbar) ||
      !validateInput(password, 'password', enqueueSnackbar)) {
      return;
    }

    postRequest("api/v2/authentication/login", { email, password }, null, false).then(async (res) => {
      const data = await res.json();
      if (res.status === 200) {
        localStorage.setItem("sessionId", data.sessionId);
        setSessionId(data.sessionId)
        getUser();
        enqueueSnackbar(t(data.message), { variant: 'success' });
        setOpenAccountPopup(false)

        const url = new URL(window.location.href);
        if (url.searchParams.get("email") && !url.searchParams.get("activationCode")) {
          const pathnameParts = url.pathname.split('/');
          const id = pathnameParts[pathnameParts.length - 1];
          navigate(`/marketplace/detail/${id}`);
        }
      } else {
        enqueueSnackbar(t(data.message), { variant: 'error' });
      }
    });
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    signInRequest();
  };

  const validateInput = (value, fieldName, enqueueSnackbar) => {
    if (!value) {
      enqueueSnackbar(`Please input your ${fieldName}`, { variant: 'error' });
      return false;
    }
    return true;
  };

  const signUpRequest = () => {
    const email = registerEmailRef.current.value;
    const password = registerPasswordRef.current.value;
    const firstName = registerNameRef.current.value;
    const lastName = registerSurnamedRef.current.value;

    if (!validateInput(email, 'email', enqueueSnackbar) ||
      !validateInput(password, 'password', enqueueSnackbar) ||
      !validateInput(firstName, 'first name', enqueueSnackbar) ||
      !validateInput(lastName, 'last name', enqueueSnackbar)) {
      return;
    }

    const body = {
      email,
      password,
      profile: {
        firstName,
        lastName
      }
    }
    postRequest("api/v2/authentication/register", body, null, false).then(async (res) => {
      const data = await res.json();
      if (res.status === 200) {
        enqueueSnackbar(t(data.message), { variant: 'success' });
        handleClickOpenSingInDlg();

        // Check for the email parameter in the URL after successful sign-up
        const url = new URL(window.location.href);
        if (url.searchParams.get("email")) {
          const pathnameParts = url.pathname.split('/');
          const id = pathnameParts[pathnameParts.length - 1];
          navigate(`/marketplace/detail/${id}`);
        }

      } else {
        enqueueSnackbar(t(data.message), { variant: 'error' });
      }
    });
  }

  const forgotPasswordRequest = () => {
    const body = {
      email: forgotPasswordEmailRef.current.value,
    }
    postRequest("api/v2/authentication/reset-password", body, null, false).then(async (res) => {
      const data = await res.json();
      if (res.status === 200) {
        enqueueSnackbar(t(data.message), { variant: 'success' });
        handleClickOpenSingInDlg();
      } else {
        enqueueSnackbar(t(data.message), { variant: 'error' });
      }
    });
  }

  const handleClickOpenSingInDlg = () => {
    closeForgotPasswordDlg();
    closeSignUpDlg();
    openSignInDlg();
  };

  const handleClickOpenSignUpDlg = () => {
    closeForgotPasswordDlg();
    closeSignInDlg();
    openSignUpDlg();
  };

  const handleClickForgotPasswordDlg = () => {
    closeSignInDlg();
    closeSignUpDlg();
    openForgotPasswordDlg();
  }

  const closeSignInDlg = () => {
    setOpenSignInDlg(false);
  };
  const closeSignUpDlg = () => {
    setOpenSignUpDlg(false);
  };
  const closeForgotPasswordDlg = () => {
    setOpenForgotPasswordDlg(false);
  };
  const openSignInDlg = () => {
    setOpenSignInDlg(true);
  };
  const openSignUpDlg = () => {
    setOpenSignUpDlg(true);
  };
  const openForgotPasswordDlg = () => {
    setOpenForgotPasswordDlg(true);
  };
  const openRedeemPointDlg = () => {
    setIsOpenRedeemPointDlg(true);
  };

  const SignInButton = () => (
    <Button id="signin" variant="contained" onClick={handleClickOpenSingInDlg}>
      {t('signIn')}
    </Button>
  );

  const openAccountMenu = (event) => {
    if (isMobile && event.type === 'mouseenter') return;
    setOpenAccountPopup(event.currentTarget);
  };

  const closeAccountMenu = async (e) => {
    if (e.target.getAttribute('value') === 'signout') {
      localStorage.removeItem("sessionId");
      setSessionId(null)
      navigate('/');
    }
    setOpenAccountPopup(false);
  };

  const { t } = useTranslation();

  const handleRedeemClick = async () => {
    try {
      const emailVal = paypalEmailRef.current?.value ?? email;
      const amountVal = amountRef.current?.value ?? amount;
      if (!emailVal || !amountVal) {
        enqueueSnackbar(t('fillInputLabels'), { variant: 'error' });
        setIsRedeeming(false);
        return;
      }
      if (amountVal <= 0) {
        enqueueSnackbar(t('cannotRedeemZeroPoints'), { variant: 'error' });
        setIsRedeeming(false);
        return;
      }
      setEmail(emailVal);
      setAmount(amountVal);

      if (!isOpenAreYouSureDlg) {
        setIsOpenAreYouSureDlg(true);
        return;
      }

      setIsRedeeming(true);
      const body = { amount, sessionId, email };
      postRequest(`api/v2/point/redeemPoint?sessionId=${sessionId}`, body, null, false).then(async (res) => {
        const data = await res.json();
        if (res.status === 200) {
          getUser();
          enqueueSnackbar(t(data.message), { variant: 'success' });
          setIsRedeeming(false);
          setIsOpenRedeemPointDlg(false);
          setEmail("")
          setAmount("")
          navigate('/');
        } else if (res.status === 401) {
          localStorage.removeItem("sessionId");
          setSessionId(null)
          navigate('/marketplace');
        }
        else {
          enqueueSnackbar(t(data.message), { variant: 'error' });
          setIsRedeeming(false);
        }
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t('errorWhileRedeemingPoints'), { variant: 'error' });
      setIsRedeeming(false);
    }
  }
  const ProfileModal = () => (
    <>
      <MFab id="profileModal" size="small" onClick={openAccountMenu} onMouseEnter={openAccountMenu}>
        {/* <AccountCircleOutlinedIcon /> */}
        <RingAvatar
          avatar={avatarUrl}
          isImage={!!avatarUrl}
          size={30}
          outersx={{
            p: '3px',
            border: '2px solid transparent',
            width: 40,
            height: 40
          }}
        />
      </MFab>
      <Menu
        keepMounted
        id="simple-menu"
        anchorEl={isOpenAccountPopup}
        onClose={closeAccountMenu}
        open={Boolean(isOpenAccountPopup) && user}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{ onMouseLeave: () => setOpenAccountPopup(null) }}
      >
        <Box sx={{ px: 2, py: '6px' }}>
          <Typography variant="h6">
            {`${user?.profile?.firstName ?? ""} ${user?.profile?.lastName ?? ""}`}
          </Typography>
        </Box>
        <Box sx={{ px: 2, py: '6px' }}>
          <Typography variant="h6">
            {user?.email ?? ""}
          </Typography>
          {user && user.wallet &&
            <Grid>
              {/* <Typography variant="h7" sx={{ color: '#aaa' }}>
                {reduceHexAddress(user.wallet.address)} <CopyButton text={user.wallet.address} sx={{ mt: '-3px' }} />
              </Typography> */}
              <Stack spacing={1}>
                <PaperRecord
                  sx={{
                    p: 1.5,
                    textAlign: 'center',
                    minWidth: 300
                  }}
                >
                  <Typography variant="h6">{t('totalBalance')}</Typography>
                  <Typography variant="h3" color='rgb(67, 160, 160)'>
                    {t('point')} {user.wallet.balance}
                  </Typography>
                  {
                    user?.paypalBalance &&
                    <>
                      <Typography variant="h6">{t('paypalBalance')}</Typography>
                      <Typography variant="h3" color='rgb(67, 160, 160)'>
                        {t('usd')} {user?.paypalBalance}
                      </Typography>
                    </>
                  }
                  {
                    user?.wallet && user?.wallet?.burnableBalance !== 0 &&
                    <>
                      <Typography variant="h6">{t('redeemableBalance')}</Typography>
                      <Typography variant="h3" color="origin.main">
                        {t('point')} {user?.wallet?.burnableBalance}
                      </Typography>
                    </>
                  }
                  {/* <Button
                    href="https://glidefinance.io/swap"
                    target="_blank"
                    variant="outlined"
                    fullWidth
                    sx={{ textTransform: 'none' }}
                    color="inherit"
                  >
                    Add funds
                  </Button> */}
                </PaperRecord>
              </Stack>
            </Grid>
          }
        </Box>
        <MenuItem to={`/profile?address=${user?.wallet?.address}`} onClick={closeAccountMenu} component={RouterLink}>
          <AccountCircleOutlinedIcon />
          &nbsp;{t('profile')}
        </MenuItem>
        {
          user && user.userType === "admin" &&
          <MenuItem to="admin-panel" onClick={closeAccountMenu} component={RouterLink}>
            <AdminPanelSettingsOutlinedIcon />
            &nbsp;{t('adminPanel')}
          </MenuItem>
        }
        {
          user && user.userType === "admin" &&
          <MenuItem to="inventory" onClick={closeAccountMenu} component={RouterLink}>
            <Inventory2OutlinedIcon />
            &nbsp;{t('inventory')}
          </MenuItem>
        }
        {
          user && user.userType === "private" &&
          <MenuItem to={`/marketplace/${user.storePath || ''}`} onClick={closeAccountMenu} component={RouterLink}>
            <StorefrontIcon />
            &nbsp;{t('myStore')}
          </MenuItem>
        }
        {/* <MenuItem onClick={closeAccountMenu}>
              <SettingsOutlinedIcon />
              &nbsp;Settings
            </MenuItem> */}
        {/* <MenuItem value="credentials" onClick={closeAccountMenu}>
              <Box component="img" alt="ico" src='/static/carbon_credentials.svg' sx={{ width: 24, filter: (theme)=>theme.palette.mode==='dark'?'invert(1)':'none' }}/>
              &nbsp;Credentials
            </MenuItem> */}
        <MenuItem value="redeem" onClick={() => { openRedeemPointDlg() }} id="redeem">
          <CurrencyExchangeOutlinedIcon />
          &nbsp;{t('redeem')}
        </MenuItem>
        <MenuItem value="signout" onClick={closeAccountMenu} id="signout">
          <LogoutOutlinedIcon />
          &nbsp;{t('signOut')}
        </MenuItem>
      </Menu>
      <RedeemPointDialog />
    </>
  )

  const SignInDialog = () => (
    <Dialog open={openSignIn} onClose={closeSignInDlg} sx={{ overflowX: 'auto', height: '75%' }}>
      <DialogStyle>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeSignInDlg}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h3" component="div" sx={{ color: 'text.primary' }} align="center">
            {t('signIn')}
          </Typography>
          <Box component="div" sx={{ maxWidth: 350, m: 'auto' }}>
            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={2} sx={{ my: 4 }}>
                <Grid item xs={12} md={12}>
                  <TextFieldStyle inputRef={loginEmailRef} id="emailInput" defaultValue={emailFromURL || ""} className='text-field username-text-field' label={`${t("email")}*`} size="small" type="text" />
                </Grid>
                <Grid item xs={12} md={12} mt={2}>
                  <TextFieldStyle inputRef={loginPasswordRef} id="passwordInput" className='text-field password-text-field' label={`${t("password")}*`} size="small" type="password" />
                </Grid>
                <Grid item xs={12} sx={{ pt: '8px !important' }}>
                  <StyledButton
                    variant="contained"
                    fullWidth
                    style={{ backgroundColor: 'rgb(72, 133, 52)' }}
                    type="submit"
                    id="submitSignIn"
                  >
                    {t('signIn')}
                  </StyledButton>
                </Grid>
                <Grid item xs={12} sx={{ pt: '15px !important' }}>
                  <StyledButton variant="outlined" id="submitSignUp" fullWidth onClick={handleClickOpenSignUpDlg}>
                    {t('signUp')}
                  </StyledButton>
                </Grid>
              </Grid>
            </form>
            <Grid className='forgot-password' item xs={12} sx={{ pt: '8px !important' }}>
              <Link onClick={handleClickForgotPasswordDlg}>{t('forgotYourPassword')}</Link>
            </Grid>
          </Box>

          <Typography
            variant="caption"
            display="block"
            sx={{ color: 'text.secondary' }}
            gutterBottom
            align="center"
            className='footer-text'
          >
            {t('signInfo')}
          </Typography>
        </DialogContent>
      </DialogStyle>
    </Dialog >);

  const RedeemPointDialog = () => (
    <Dialog
      open={isOpenRedeemPointDlg}
      onClose={(event, reason) => {
        if ((reason && reason === "backdropClick") || isRedeeming)
          return;
        setIsOpenRedeemPointDlg(false);
      }}
    >
      <DialogStyle>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              if (isRedeeming)
                return;
              setIsOpenRedeemPointDlg(false)
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="h3"
            component="div"
            sx={{ color: "text.primary" }}
            align="center"
          >
            {t('redeemPoints')}
          </Typography>
          {!searchParams.get("code") ?
            <Grid sx={{ padding: 8 }}>
              <PaypalLoginButton />
            </Grid>
            :
            <Box component="div" sx={{ maxWidth: 400, m: "auto" }}>
              {isOpenAreYouSureDlg ?
                <Grid container spacing={2} sx={{ my: 4 }}>
                  <Grid item xs={12} md={12}>
                    <Typography>{t('redeemEmail')}: {email}</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography>{t('redeemAmount')}: {amount}</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="body2" component="span" color='text.secondary'>{t('areYouSureRedeem')}</Typography>
                  </Grid>
                  <Grid container spacing={1} justifyContent="space-between" item xs={12} sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        onClick={() => setIsOpenAreYouSureDlg(false)}
                        fullWidth
                        disabled={isRedeeming}
                        disableBackdropClick>{t('cancel')}</Button>
                    </Grid>
                    <Grid item xs={6}>
                      <LoadingButton
                        loading={isRedeeming}
                        variant="contained"
                        onClick={handleRedeemClick}
                        fullWidth
                        disableBackdropClick
                      >
                        {t('redeemPoints')}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
                : <Grid container spacing={2} sx={{ my: 4 }}>
                  <Grid item xs={12} md={12}>
                    <TextFieldStyle
                      inputRef={paypalEmailRef}
                      className="text-field email-text-field"
                      label="Email"
                      size="small"
                      type="email"
                      required
                      defaultValue={email}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextFieldStyle
                      inputRef={amountRef}
                      className="text-field amount-text-field"
                      label="Amount"
                      size="small"
                      type="number"
                      inputProps={{ pattern: "[0-9]*" }}
                      required
                      defaultValue={amount}
                      disabled={isRedeeming}
                    />
                  </Grid>
                  {user && user.wallet && user.wallet.burnableBalance &&
                    <Grid item xs={12} md={12}>
                      <Typography>{t('redeemablePoint')}: {user.wallet.burnableBalance}</Typography>
                    </Grid>}
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <LoadingButton
                      loading={isRedeeming}
                      variant="contained"
                      onClick={handleRedeemClick}
                      fullWidth
                      disableBackdropClick
                    >
                      {t('redeemPoints')}
                    </LoadingButton>
                  </Grid>
                </Grid>}
            </Box>}
        </DialogContent>
      </DialogStyle>
    </Dialog>
  );


  const SignUpDialog = () => (
    <Dialog open={openSignUp} onClose={closeSignUpDlg}>
      <DialogStyle>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeSignUpDlg}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h3" component="div" sx={{ color: 'text.primary' }} align="center">
            {t('signUp')}
          </Typography>
          <Box component="div" sx={{ maxWidth: 350, m: 'auto' }}>
            <Grid container spacing={2} sx={{ my: 4 }}>
              <Grid item xs={12} md={12}>
                <TextFieldStyle inputRef={registerNameRef} id="nameSignUp" className='text-field name-text-field' label={`${t("userName")}*`} size="small" type="text" />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextFieldStyle inputRef={registerSurnamedRef} id="surnameSignUp" className='text-field surname-text-field' label={`${t("surname")}*`} size="small" type="text" />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextFieldStyle inputRef={registerEmailRef} id="emailSignUp" defaultValue={emailFromURL || ""} className='text-field username-text-field' label={`${t("email")}*`} size="small" type="text" />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextFieldStyle inputRef={registerPasswordRef} id="passwordSignUp" className='text-field password-text-field' label={`${t("password")}*`} size="small" type="password" />
              </Grid>
              <Grid item xs={12} sx={{ pt: '8px !important' }}>
                <StyledButton
                  id="signUpButton"
                  variant="contained"
                  fullWidth
                  onClick={async () => {
                    signUpRequest();
                  }}
                >
                  {t('signUp')}
                </StyledButton>
              </Grid>
              <Grid item xs={12} sx={{ pt: '15px !important' }}>
                <StyledButton variant="contained" style={{ backgroundColor: 'rgb(72, 133, 52)' }} fullWidth onClick={handleClickOpenSingInDlg}>
                  {t('signIn')}
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
          <Typography
            variant="caption"
            display="block"
            sx={{ color: 'text.secondary' }}
            gutterBottom
            align="center"
          >
            {t('signInfo')}
          </Typography>
        </DialogContent>
      </DialogStyle>
    </Dialog>
  );

  const ForgotPasswordDialog = () => (
    <Dialog open={openForgotPassword} onClose={closeForgotPasswordDlg} sx={{ overflowX: 'auto', height: '75%' }}>
      <DialogStyle>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeForgotPasswordDlg}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography variant="h3" component="div" sx={{ color: 'text.primary' }} align="center">
            {t('forgotPassword')}
          </Typography>
          <Box component="div" sx={{ maxWidth: 350, m: 'auto' }}>
            <Grid container spacing={2} sx={{ my: 4 }}>
              <Grid item xs={12} md={12}>
                <TextFieldStyle inputRef={forgotPasswordEmailRef} className='text-field username-text-field' label={t("email")} size="small" type="text" />
              </Grid>
              <Grid item xs={12} sx={{ pt: '8px !important' }}>
                <StyledButton
                  variant="contained"
                  fullWidth
                  onClick={async () => {
                    forgotPasswordRequest();
                  }}
                >
                  {t('sendEmail')}
                </StyledButton>
              </Grid>
              <Grid item xs={12} sx={{ pt: '15px !important' }}>
                <StyledButton variant="contained" style={{ backgroundColor: 'rgb(72, 133, 52)' }} fullWidth onClick={handleClickOpenSingInDlg}>
                  {t('signIn')}
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
          <Typography
            variant="caption"
            display="block"
            sx={{ color: 'text.secondary' }}
            gutterBottom
            align="center"
            className='footer-text'
          >
            {t('signInfo')}
          </Typography>
        </DialogContent>
      </DialogStyle>
    </Dialog >);

  return (
    <div style={{ minWidth: 79 }}>
      {sessionId
        ?
        ProfileModal()
        :
        <>
          <SignInButton />
          <SignInDialog />
          <SignUpDialog />
          <ForgotPasswordDialog />
        </>}
    </div>
  );
}