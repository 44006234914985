import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// import telegramIcon from '@iconify/icons-ic/outline-telegram';
import twitterIcon from '@iconify/icons-ant-design/twitter';
import mediumIcon from '@iconify/icons-ant-design/medium';
import githubIcon from '@iconify/icons-ant-design/github'
import discordIcon from '@iconify/icons-ic/baseline-discord';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, List, Link, Drawer, Typography, Grid, Paper, CardActionArea, ListItemText, ListItemIcon, ListItemButton, Container } from '@mui/material';
// components
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import ModeSwitch from '../../components/mode-switch';
import { MIconButton, MFab } from '../../components/@material-extend';
import DonateDlg from '../../components/dialog/Donate';
import useSingin from '../../hooks/useSignin';
import useSettings from '../../hooks/useSettings';
import { isInAppBrowser } from '../../utils/common';
import { PATH_DOCS } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = 22;
const ITEM_SIZE = 48;
const PADDING = 2.5;

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: ITEM_SIZE,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(PADDING),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary
}));

const SOCIALS = [
  // { name: 'TelegramIcon', icon: telegramIcon },
  { name: 'Medium', icon: mediumIcon, url: 'https://medium.com/@protocolpasar' },
  { name: 'Twitter', icon: twitterIcon, url: 'https://twitter.com/PasarProtocol' },
  { name: 'Discord', icon: discordIcon, url: 'https://discord.gg/RPbcBv8ckh' },
  { name: 'Github', icon: githubIcon, url: 'https://github.com/PasarProtocol' }
];
// ----------------------------------------------------------------------

MenuMobileItem.propTypes = {
  item: PropTypes.object,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func
};

function MenuMobileItem(props) {
  const { item, isOpen, onOpen } = props
  const { user } = useSingin()
  const { title, path, icon, children } = item;
  const navigate = useNavigate();

  if (children) {
    return (
      <>
        <ListItemStyle onClick={onOpen}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText disableTypography primary={title} />
          <Box
            component={Icon}
            icon={isOpen ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>
      </>
    );
  }

  if (!item.disable && title === 'Docs') {
    return (
      <ListItemStyle href={path} target="_blank" component={Link}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText disableTypography primary={title} />
      </ListItemStyle>
    );
  }

  if (path.startsWith("/create")) {
    if (user)
      return <ListItemStyle
        to={path}
        component={RouterLink}
        // end={path === '/'}
        sx={{
          '&.active': {
            color: 'text.primary',
            fontWeight: 'fontWeightMedium',
            bgcolor: (theme) => alpha(theme.palette.text.primary, theme.palette.action.selectedOpacity)
          }
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText disableTypography primary={title} />
      </ListItemStyle>
    return <ListItemStyle
      // end={path === '/'}
      sx={{
        cursor: 'pointer',
        '&.active': {
          color: 'text.primary',
          fontWeight: 'fontWeightMedium',
          bgcolor: (theme) => alpha(theme.palette.text.primary, theme.palette.action.selectedOpacity)
        }
      }}
    // onClick={e => openSignin(path)}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  }

  if (path.startsWith("/profile")) {
    if (user)
      return <ListItemStyle
        to={`${path}?address=${user.wallet.address}&tab=1`}
        component={RouterLink}
        // end={path === '/'}
        sx={{
          '&.active': {
            color: 'text.primary',
            fontWeight: 'fontWeightMedium',
            bgcolor: (theme) => alpha(theme.palette.text.primary, theme.palette.action.selectedOpacity)
          }
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText disableTypography primary={title} />
      </ListItemStyle>
    return <ListItemStyle
      sx={{
        cursor: 'pointer',
        '&.active': {
          color: 'text.primary',
          fontWeight: 'fontWeightMedium',
          bgcolor: (theme) => alpha(theme.palette.text.primary, theme.palette.action.selectedOpacity)
        }
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  }

  return (
    !item.disable ?
      <ListItemStyle
        to={path}
        component={RouterLink}
        // end={path === '/'}
        sx={{
          '&.active': {
            color: 'text.primary',
            fontWeight: 'fontWeightMedium',
            bgcolor: (theme) => alpha(theme.palette.text.primary, theme.palette.action.selectedOpacity)
          }
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText disableTypography primary={title} />
      </ListItemStyle> :

      <ListItemStyle
        disabled={1 && true}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText disableTypography primary={title} />
      </ListItemStyle>
  );
}

function CopyRight(prop) {
  return (
    <Typography
      component="p"
      variant="body2"
      sx={{
        color: 'text.secondary',
        textAlign: 'center'
      }}
    >
      {prop.children}
    </Typography>
  );
}

const initialValueHandler = () => {
  if (window.location.pathname === '/profile') {
    return 'savedDesign';
  } if (window.location.pathname === '/apollo-b') {
    return 'apolloB';
  } if (window.location.pathname === '/collections') {
    return 'collections';
  } if (window.location.pathname === '/marketplace') {
    return 'marketplace';
  }
  return '';
}

MenuMobile.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool,
  navConfig: PropTypes.array
};

export default function MenuMobile(props) {
  const { isOffset, isHome, navConfig } = props
  const { store } = useSingin()
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [donateOpen, setDonateOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValueHandler());

  const { themeMode } = useSettings();
  const fabColorType = themeMode === 'light' ? 'primary' : 'default'

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // const handleOpen = () => {
  //   setOpen(!open);
  // };

  // const handleDrawerOpen = () => {
  //   setDrawerOpen(true);
  // };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Box sx={{ pb: 7 }}>
        <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            {navConfig.map((link) => (
              <Link
                onClick={(e) => { setValue(link.title) }}
                sx={{ textDecoration: 'none !important', padding: 2, flexDirection: 'column', justifyContent: 'center', display: 'flex', width: '100%', backgroundColor: 'transparent' }}
                key={link.title}
                label={link.title}
                icon={link.icon}
                component={RouterLink}
                to={link.path}
              >
                <Box sx={{ justifyContent: 'center', display: 'flex', width: '100%', color: value === link.title ? '#9EE7E7' : 'black' }}>
                  {link.icon}
                </Box>
                <Typography sx={{ whiteSpace: 'nowrap', mt: .5, color: value === link.title ? '#9EE7E7' : 'black' }} variant="caption" align="center">
                  {`${t(link.title)}`}
                </Typography>
              </Link>
            ))}
          </BottomNavigation>
        </Box>
      </Box>

      {/* <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: 260 } }}
      >
        <Scrollbar sx={{ minHeight: 250 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Link component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
                <Logo sx={{ mx: PADDING, my: 3 }} />
              </Link>
            </div>
            {store && store.storePhoto && (
              <img
                draggable={false}
                src={store.storePhoto}
                alt="Store Logo"
                style={{
                  minWidth: '50px',
                  width: '50px',
                }}
              />
            )}
          </div>
          <List disablePadding>
            {navConfig.map((link) => (
              <MenuMobileItem
                key={link.title}
                item={link}
                isOpen={open}
                onOpen={handleOpen}
              />
            ))}
          </List>
        </Scrollbar>
        <Box sx={{ flexGrow: 1 }} />
        <Container>
          <Grid container dir="ltr" sx={{ pb: 1 }}>
            {
              SOCIALS.map((social, _i) => (
                <Grid key={_i} item xs={3} align="center">
                  <MFab size="small" color={fabColorType} href={social.url} component={Link} target="_blank">
                    <Icon icon={social.icon} width={20} height={22} />
                  </MFab>
                </Grid>
              ))
            }
          </Grid>
          <Box
            sx={{
              pb: 1,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <ModeSwitch />
          </Box>
          <CopyRight>
            Open Diamond 2022 |{' '}
          </CopyRight>
          <CopyRight>
            <Link
              href={PATH_DOCS}
              target="_blank"
              underline="always"
              sx={{ color: 'inherit' }}
            >
              Docs
            </Link>
            {' '}| Privacy Policy | Disclaimer️
          </CopyRight>
          <CopyRight>
            v2 -
          </CopyRight>
        </Container>
      </Drawer> */}
    </>
  );
}